import React from "react"
import { graphql } from "gatsby"
import { localize } from "../lib/helpers"

import Header from "../components/header"
import Seo from "../components/seo"
import Cta from "../components/cta"

const NotFoundPage = ({ data, pageContext }) => {
  const
    { _rawTitle, _rawInstruction, _rawQuote, _rawCta } = localize(data.sanityError, [pageContext.language]);

  return (
    <div className="error-container">
      <Header lang={pageContext.language} />
      <Seo title={_rawTitle} lang={pageContext.language}/>
      <main>
        <div className="left-container">
          <h1 data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>404</h1>
          <p data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M16-regular instruction">{_rawInstruction}</p>
        </div>
        <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="right-container">
          <q>{_rawQuote.quote}</q>
          <p className="M16-regular author">- {_rawQuote.author}</p>
          <Cta cta={_rawCta}/>
        </div>
      </main>
    </div>
 )
}

export const query = graphql`
  query ErrorPage {
    sanityError {
      _rawTitle
      _rawInstruction,
      _rawQuote,
      _rawCta
    }
  }
`

export default NotFoundPage
